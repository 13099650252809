<template>
  <div class="inner-div">
    <VasionSchedulersPage title="Advanced Image Processing" />
  </div>
</template>

<script>
export default {
  name: 'ScheduleAip',
}
</script>

<style lang="scss" scoped>

  .inner-div {
    padding-top: 8px;
    margin-right: 8px;
  }

</style>
